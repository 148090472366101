<template>
  <div>
    <!-- 检索 -->
    <el-form :inline="true" :model="form" ref="form">
      <el-form-item class="form-item" label="用户所属" prop="bg_app">
        <el-select v-model="form.bg_app" placeholder="全部" clearable>
          <el-option label="纠音 App" value="1"></el-option>
          <el-option label="Uummii App" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" label="用户手机" prop="phone">
        <el-input
          v-model="form.phone"
          placeholder="请输入账号/昵称"
          @keyup.enter="onSubmit"
        ></el-input>
      </el-form-item>
      <el-form-item class="form-item" label="问题类型" prop="type">
        <el-select v-model="form.type" placeholder="全部" clearable>
          <el-option label="BUG" value="0"></el-option>
          <el-option label="建议" value="1"></el-option>
          <el-option label="吐槽" value="2"></el-option>
          <el-option label="其他" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" label="处理状态" prop="status">
        <el-select v-model="form.status" placeholder="全部" clearable>
          <el-option label="待处理" value="0"></el-option>
          <el-option label="已处理" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" label="反馈周期" prop="str_time">
        <el-date-picker
          v-model="str_time"
          :editable="false"
          type="datetimerange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          class="date-input"
          @change="handCreateTime"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item class="form-item form-submit">
        <el-button type="primary" @click="onSubmit">查询</el-button>
        <el-button @click="resetForm('form')">重置</el-button>
      </el-form-item>
    </el-form>
    <!-- 检索 -->

    <div class="table-content">
      <div class="table-title">
        <div>反馈列表</div>
      </div>

      <!-- 列表 -->
      <el-table
        :data="tableData"
        border
        tooltip-effect="dark"
        style="width: 100%"
        v-loading="loading"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
      >
        <el-table-column
          fixed="left"
          prop="bg_app"
          label="所属产品"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="phone"
          label="用户信息"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="type"
          label="问题类型"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="is_pic"
          label="是否有截图"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="createdtime"
          label="反馈时间"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="反馈信息"
          show-overflow-tooltip
          min-width="200"
          align="center"
        >
          <template #default="scope">
            <div class="form-content">{{ scope.row.content }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" width="150" align="center">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="150" align="center">
          <template #default="scope">
            <el-button
              v-if="type.indexOf('处理') > -1 && scope.row.status === '待处理'"
              @click="handClick(scope.row.id)"
              type="text"
              size="small"
              >处理</el-button
            >
            <el-button
              v-if="type.indexOf('详情') > -1 && scope.row.status === '已处理'"
              @click="handleClick(scope.row.id)"
              type="text"
              size="small"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 列表 -->

      <!-- 分页 -->
      <Pagination
        :pageSize="pageSize"
        :currentPage="currentPage"
        :total="total"
        @handSizeChange="handSizeChange"
        @handCurrentChange="handCurrentChange"
      ></Pagination>
      <!-- 分页 -->
    </div>

    <!-- 处理弹窗 -->
    <el-dialog title="反馈处理" v-model="dialogFormVisible" width="500px">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        v-loading="load"
      >
        <el-form-item class="feedback-item" label="用户昵称：">
          <div>{{ feedbackData.username }}</div>
        </el-form-item>
        <el-form-item class="feedback-item" label="用户手机：">
          <div>{{ feedbackData.phone }}</div>
        </el-form-item>
        <el-form-item class="feedback-item" label="问题类型：">
          <div>{{ feedbackData.type }}</div>
        </el-form-item>
        <el-form-item class="feedback-item" label="反馈截图：">
          <div v-if="imgList.length > 0">
            <el-image
              v-for="(item, index) in imgList"
              :key="index"
              :src="item.filepath"
              :preview-src-list="srcList"
              :initial-index="index"
              fit="cover"
              class="feedback-img"
            ></el-image>
          </div>
          <div v-else>无</div>
        </el-form-item>
        <el-form-item class="feedback-item" label="反馈时间：">
          <div>{{ feedbackData.createdtime }}</div>
        </el-form-item>
        <el-form-item class="feedback-item" label="反馈信息：">
          <div>{{ feedbackData.content }}</div>
        </el-form-item>
        <el-form-item class="feedback-item" label="是否致电：" prop="is_phone">
          <el-radio-group v-model="ruleForm.is_phone">
            <el-radio label="1">是</el-radio>
            <el-radio label="2">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          class="feedback-item"
          label="处理方式："
          prop="handle_content"
        >
          <el-input
            type="textarea"
            v-model="ruleForm.handle_content"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="submitForm('ruleForm')"
            >确 定</el-button
          >
          <el-button @click="dialogFormVisible = false">取 消</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 处理弹窗 -->

    <!-- 查看弹窗 -->
    <el-dialog title="查看详情" v-model="dialogLook" width="500px">
      <el-form label-width="100px" v-loading="load">
        <el-form-item class="feedback-item" label="用户昵称：">
          <div>{{ feedbackData.username }}</div>
        </el-form-item>
        <el-form-item class="feedback-item" label="用户手机：">
          <div>{{ feedbackData.phone }}</div>
        </el-form-item>
        <el-form-item class="feedback-item" label="问题类型：">
          <div>{{ feedbackData.type }}</div>
        </el-form-item>
        <el-form-item class="feedback-item" label="反馈截图：">
          <div v-if="imgList.length > 0">
            <el-image
              v-for="(item, index) in imgList"
              :key="index"
              :src="item.filepath"
              :preview-src-list="srcList"
              :initial-index="index"
              fit="cover"
              class="feedback-img"
            ></el-image>
          </div>
          <div v-else>无</div>
        </el-form-item>
        <el-form-item class="feedback-item" label="反馈时间：">
          <div>{{ feedbackData.createdtime }}</div>
        </el-form-item>
        <el-form-item class="feedback-item" label="反馈信息：">
          <div>{{ feedbackData.content }}</div>
        </el-form-item>
        <el-form-item class="feedback-item" label="是否致电：">
          <div>{{ feedbackData.is_phone }}</div>
        </el-form-item>
        <el-form-item class="feedback-item" label="处理方式：">
          <div>{{ feedbackData.handle_content }}</div>
        </el-form-item>
        <el-form-item class="feedback-item" label="处理人：">
          <div>
            <span style="margin-right: 20px">{{
              feedbackData.handler_name
            }}</span>
            <span>{{ feedbackData.handler_username }}</span>
          </div>
        </el-form-item>
        <el-form-item class="feedback-item" label="处理时间：">
          <div>{{ feedbackData.modifiedtime }}</div>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogLook = false">关 闭</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 查看弹窗 -->
  </div>
</template>

<script>
import Pagination from "@/components/pagination/pagination.vue";
import {
  suggestionList,
  suggestionDetail,
  suggestionEdit,
} from "@/api/apiList/operate-api";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      form: {
        bg_app: "",
        phone: "",
        type: "",
        status: "",
        str_time: "",
        pageSize: 10,
        page: 1,
      },
      ruleForm: {
        id: "",
        is_phone: "",
        handle_content: "",
      },
      rules: {
        is_phone: [
          { required: true, message: "请选择是否致电用户", trigger: "change" },
        ],
        handle_content: [
          { required: true, message: "请填写处理方式", trigger: "blur" },
        ],
      },
      str_time: "",
      tableData: [],
      feedbackData: {},
      imgList: [],
      srcList: [],
      loading: true,
      load: true,
      pageSize: 10,
      currentPage: 1,
      total: 10,
      type: "",
      dialogFormVisible: false,
      dialogLook: false,
    };
  },
  methods: {
    // 检索时间格式转换
    handCreateTime(e) {
      if (e) {
        let start_m =
          new Date(e[0]).getMonth() + 1 > 9
            ? new Date(e[0]).getMonth() + 1
            : "0" + (new Date(e[0]).getMonth() + 1);
        let start_d =
          new Date(e[0]).getDate() > 9
            ? new Date(e[0]).getDate()
            : "0" + new Date(e[0]).getDate();
        let start_h =
          new Date(e[0]).getHours() > 9
            ? new Date(e[0]).getHours()
            : "0" + new Date(e[0]).getHours();
        let start_f =
          new Date(e[0]).getMinutes() > 9
            ? new Date(e[0]).getMinutes()
            : "0" + new Date(e[0]).getMinutes();
        let start_s =
          new Date(e[0]).getSeconds() > 9
            ? new Date(e[0]).getSeconds()
            : "0" + new Date(e[0]).getSeconds();
        let end_m =
          new Date(e[1]).getMonth() + 1 > 9
            ? new Date(e[1]).getMonth() + 1
            : "0" + (new Date(e[1]).getMonth() + 1);
        let end_d =
          new Date(e[1]).getDate() > 9
            ? new Date(e[1]).getDate()
            : "0" + new Date(e[1]).getDate();
        let end_h =
          new Date(e[1]).getHours() > 9
            ? new Date(e[1]).getHours()
            : "0" + new Date(e[1]).getHours();
        let end_f =
          new Date(e[1]).getMinutes() > 9
            ? new Date(e[1]).getMinutes()
            : "0" + new Date(e[1]).getMinutes();
        let end_s =
          new Date(e[1]).getSeconds() > 9
            ? new Date(e[1]).getSeconds()
            : "0" + new Date(e[1]).getSeconds();
        this.form.str_time =
          new Date(e[0]).getFullYear() +
          "-" +
          start_m +
          "-" +
          start_d +
          " " +
          start_h +
          ":" +
          start_f +
          ":" +
          start_s +
          "~" +
          new Date(e[1]).getFullYear() +
          "-" +
          end_m +
          "-" +
          end_d +
          " " +
          end_h +
          ":" +
          end_f +
          ":" +
          end_s;
      } else {
        this.form.str_time = "";
      }
    },
    // 查询
    onSubmit() {
      this.loading = true;
      this.handList();
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.str_time = "";
    },
    // 处理弹窗
    handClick(id) {
      this.dialogFormVisible = true;
      this.ruleForm = {
        id: id,
        is_phone: "",
        handle_content: "",
      };
      this.load = true;
      this.handDetail(id);
    },
    // 反馈处理
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          suggestionEdit(this.ruleForm).then((res) => {
            if (res.result === "200") {
              this.dialogFormVisible = false;
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.handList();
            }
          });
        } else {
          return false;
        }
      });
    },
    // 查看弹窗
    handleClick(id) {
      this.dialogLook = true;
      this.handDetail(id);
    },
    handSizeChange(e) {
      this.loading = true;
      this.pageSize = e;
      this.form.pageSize = e;
      this.handList();
    },
    handCurrentChange(e) {
      this.loading = true;
      this.currentPage = e;
      this.form.page = e;
      this.handList();
    },
    handList() {
      suggestionList(this.form).then((res) => {
        if (res.result === "200") {
          res.data.data.forEach((item) => {
            switch (item.bg_app) {
              case 1:
                item.bg_app = "纠音 APP";
                break;
              case 2:
                item.bg_app = "Uummii APP";
                break;
            }
            switch (item.type) {
              case 0:
                item.type = "BUG";
                break;
              case 1:
                item.type = "建议";
                break;
              case 2:
                item.type = "吐槽";
                break;
              case 3:
                item.type = "其他";
                break;
            }
            switch (item.is_pic) {
              case 0:
                item.is_pic = "否";
                break;
              case 1:
                item.is_pic = "是";
                break;
            }
            switch (item.status) {
              case 0:
                item.status = "待处理";
                break;
              case 1:
                item.status = "已处理";
                break;
            }
          });
          this.tableData = res.data.data;
          this.total = res.data.total;
          this.loading = false;
        }
      });
    },
    handDetail(e) {
      let obj = {
        id: e,
      };
      suggestionDetail(obj).then((res) => {
        if (res.result === "200") {
          switch (res.data.type) {
            case 0:
              res.data.type = "BUG";
              break;
            case 1:
              res.data.type = "建议";
              break;
            case 2:
              res.data.type = "吐槽";
              break;
            case 3:
              res.data.type = "其他";
              break;
          }
          switch (res.data.is_phone) {
            case 1:
              res.data.is_phone = "是";
              break;
            case 2:
              res.data.is_phone = "否";
              break;
          }
          this.imgList = res.data.suggestion_pic;
          if (res.data.suggestion_pic.length > 0) {
            this.srcList = res.data.suggestion_pic.map((item) => item.filepath);
          }
          this.feedbackData = res.data;
          this.load = false;
        }
      });
    },
  },
  mounted() {
    this.type = this.$store.state.type;
    this.handList();
  },
};
</script>

<style lang="scss">
.form-content {
  min-width: 178px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.feedback-item {
  .feedback-img {
    width: 100px;
    height: 100px;
    margin-right: 20px;
  }
}
</style>
